export function ArrowIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99997 0.5C9.03754 0.5 11.5 2.96243 11.5 6C11.5 9.03757 9.03754 11.5 5.99997 11.5C2.9624 11.5 0.499969 9.03757 0.499969 6C0.499969 2.96243 2.9624 0.5 5.99997 0.5ZM5.99997 1.4C3.45946 1.4 1.39997 3.45949 1.39997 6C1.39997 8.54051 3.45946 10.6 5.99997 10.6C8.54048 10.6 10.6 8.54051 10.6 6C10.6 3.45949 8.54048 1.4 5.99997 1.4ZM7.74997 3.75C8.02611 3.75 8.24997 3.97386 8.24997 4.25V7.25C8.24997 7.52614 8.02611 7.75 7.74997 7.75C7.47383 7.75 7.24997 7.52614 7.24997 7.25V5.457L4.60352 8.10355C4.42328 8.2838 4.13967 8.29766 3.94352 8.14515L3.89642 8.10355C3.70115 7.90829 3.70115 7.59171 3.89642 7.39645L6.54197 4.75H4.74997C4.49355 4.75 4.28222 4.55698 4.25333 4.30831L4.24997 4.25C4.24997 3.97386 4.47383 3.75 4.74997 3.75H7.74997Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-3.05176e-05" width="16" height="16" rx="8" fill="#007AFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3902 7.61484L6.81433 11.1907C6.59202 11.413 6.51694 11.4808 6.42592 11.5499C6.3349 11.6191 6.24608 11.6713 6.14146 11.7173C6.03684 11.7633 5.94109 11.796 5.63878 11.8824L3.85562 12.3918C3.74912 12.4223 3.63812 12.3606 3.60769 12.2541C3.5974 12.2181 3.5974 12.1799 3.60769 12.1439L4.11717 10.3608C4.20354 10.0585 4.23623 9.96271 4.28222 9.85809C4.32822 9.75347 4.38049 9.66464 4.44961 9.57362C4.51872 9.4826 4.58655 9.40753 4.80886 9.18522L8.38472 5.60938C8.44721 5.5469 8.54851 5.5469 8.611 5.60938L10.3902 7.38857C10.4527 7.45105 10.4527 7.55236 10.3902 7.61484C10.3902 7.61484 10.3902 7.61484 10.3902 7.61484ZM10.001 3.99307C10.2539 3.74022 10.3719 3.67486 10.5138 3.63186C10.6557 3.58886 10.8007 3.58886 10.9426 3.63186C11.0845 3.67486 11.2025 3.74022 11.4554 3.99307L12.0065 4.54422C12.2594 4.79707 12.3247 4.91508 12.3677 5.05695C12.4107 5.19883 12.4107 5.34392 12.3677 5.48579C12.3247 5.62766 12.2594 5.74568 12.0065 5.99853L11.4673 6.53772C11.4048 6.60021 11.3035 6.60021 11.241 6.53772L9.46184 4.75854C9.39936 4.69606 9.39936 4.59475 9.46184 4.53227C9.46184 4.53227 9.46184 4.53227 9.46184 4.53227L10.001 3.99307Z"
        fill="white"
      />
    </svg>
  );
}

export function EyeIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0006 11.5647C11.5789 11.5647 14 8.67733 14 7.78535C14 6.89057 11.5753 4.00757 8.0006 4.00757C4.47121 4.00757 2 6.89057 2 7.78535C2 8.67733 4.47121 11.5647 8.0006 11.5647ZM8.00222 10.1982C6.65352 10.1982 5.57529 9.10033 5.57529 7.78615C5.57529 6.43668 6.65352 5.37406 8.00222 5.37406C9.34005 5.37406 10.4255 6.43748 10.4255 7.78615C10.4255 9.10033 9.34005 10.1982 8.00222 10.1982ZM8.0006 8.68403C8.49747 8.68403 8.90654 8.27861 8.90654 7.78773C8.90654 7.29248 8.49747 6.88545 8.0006 6.88545C7.50174 6.88545 7.09425 7.29248 7.09425 7.78773C7.09425 8.27861 7.50174 8.68403 8.0006 8.68403Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ClickIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.12733 1.33325C3.40233 1.33325 2 2.73242 2 4.45636C2 5.1611 2.24613 5.8553 2.64837 6.37613C2.76487 6.5298 2.9095 6.58936 3.04721 6.58936C3.39448 6.58936 3.67014 6.1946 3.3987 5.84885C3.09596 5.47169 2.91324 4.98166 2.91324 4.45636C2.91324 3.23452 3.90443 2.24755 5.12627 2.24755C6.30487 2.24755 7.26911 3.17545 7.31972 4.35404C7.32182 4.3871 7.32292 4.42226 7.32397 4.46972C7.34772 4.75031 7.52939 4.89705 7.76333 4.89705C8.00621 4.89705 8.21269 4.73015 8.22709 4.48286C8.23194 4.43656 8.22979 4.36378 8.22454 4.28548C8.14333 2.62905 6.77848 1.33325 5.12733 1.33325Z"
        fill="currentColor"
      />
      <path
        d="M10.9415 14.367C13.4074 13.4723 14.2434 11.4171 13.2853 8.78558L12.8906 7.69383C12.5247 6.7016 11.8202 6.24484 11.2548 6.44712C11.1138 6.50032 11.0644 6.60619 11.1128 6.73756L11.2838 7.21167C11.3616 7.42275 11.2986 7.56292 11.1723 7.61247C11.0391 7.65728 10.8983 7.59387 10.8194 7.38064L10.707 7.07275C10.4876 6.47422 9.96206 6.21707 9.44029 6.40821C9.19756 6.49767 9.11775 6.64659 9.19546 6.85627L9.43299 7.50351C9.50605 7.71669 9.44414 7.85476 9.32157 7.90431C9.18351 7.95392 9.04169 7.88781 8.96383 7.67353L8.74865 7.077C8.50686 6.41441 8.00525 6.22398 7.48778 6.41351C7.2568 6.49707 7.17003 6.65669 7.24294 6.85947L7.70741 8.13089C7.78527 8.34302 7.71751 8.48319 7.59704 8.5317C7.45897 8.5813 7.3182 8.5131 7.23929 8.29987L5.70888 4.08889C5.55394 3.65547 5.16529 3.47451 4.79317 3.60812C4.39761 3.7524 4.22778 4.13835 4.38272 4.57176L6.61064 10.6929C6.66022 10.8358 6.6042 10.9387 6.51676 10.9718C6.43144 11.0038 6.3392 10.9766 6.23465 10.8614L4.73914 9.2524C4.50591 9.00426 4.24619 8.9193 3.95997 9.02676C3.55434 9.17318 3.38673 9.54844 3.5139 9.90498C3.56184 10.0344 3.62946 10.1387 3.69439 10.2201L5.63071 12.629C7.24234 14.635 9.09594 15.0374 10.9415 14.367Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LeadIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.32398 14.6565H13.1305C13.9589 14.6565 14.4532 14.2641 14.4532 13.6126C14.4532 11.7164 12.0487 9.10852 8.22356 9.10852C4.40578 9.10852 2 11.7164 2 13.6126C2 14.2641 2.49557 14.6565 3.32398 14.6565ZM8.2296 7.79986C9.83603 7.79986 11.2029 6.37153 11.2029 4.525C11.2029 2.71384 9.832 1.33325 8.2296 1.33325C6.62717 1.33325 5.25764 2.73801 5.25764 4.53841C5.25764 6.37153 6.61847 7.79986 8.2296 7.79986Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function HeartIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.7779C8.15146 13.7779 8.37246 13.6626 8.55443 13.5502C11.8708 11.4117 14 8.90577 14 6.36784C14 4.18276 12.4914 2.66675 10.5984 2.66675C9.41977 2.66675 8.53415 3.31789 8 4.29372C7.47563 3.32276 6.57915 2.66675 5.4016 2.66675C3.50864 2.66675 2 4.18276 2 6.36784C2 8.90577 4.12914 11.4117 7.45044 13.5502C7.62754 13.6626 7.84748 13.7779 8 13.7779Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function StatsIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#007AFF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2 4C11.6418 4 12 4.35817 12 4.8V11.2C12 11.6418 11.6418 12 11.2 12C10.7582 12 10.4 11.6418 10.4 11.2V4.8C10.4 4.35817 10.7582 4 11.2 4ZM4.8 8C5.24183 8 5.6 8.35817 5.6 8.8V11.2C5.6 11.6418 5.24183 12 4.8 12C4.35817 12 4 11.6418 4 11.2V8.8C4 8.35817 4.35817 8 4.8 8ZM8 6C8.44183 6 8.8 6.35817 8.8 6.8V11.2C8.8 11.6418 8.44183 12 8 12C7.55817 12 7.2 11.6418 7.2 11.2V6.8C7.2 6.35817 7.55817 6 8 6Z"
        fill="white"
      />
    </svg>
  );
}

export function ChatIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.56173 10.2263C4.35014 10.4221 4.21491 10.5062 4.03955 10.5062C3.78402 10.5062 3.63606 10.3246 3.63606 10.0456V8.81657H3.46494C2.23292 8.81657 1.5 8.09001 1.5 6.82762V3.53784C1.5 2.27545 2.21536 1.54541 3.49166 1.54541H8.90257C10.1796 1.54541 10.895 2.27892 10.895 3.53784V6.82762C10.895 8.08654 10.1796 8.81657 8.90257 8.81657H6.11977L4.56173 10.2263ZM7.67944 5.17886C7.67944 5.54352 7.97239 5.83722 8.33625 5.83722C8.70167 5.83722 8.99461 5.54352 8.99461 5.17886C8.99461 4.815 8.70167 4.52129 8.33625 4.52129C7.97239 4.52129 7.67944 4.815 7.67944 5.17886ZM6.20252 5.83722C5.83786 5.83722 5.54068 5.54352 5.54068 5.17886C5.54068 4.815 5.83786 4.52129 6.20252 4.52129C6.5629 4.52129 6.86009 4.815 6.86009 5.17886C6.86009 5.54352 6.5629 5.83722 6.20252 5.83722ZM3.40692 5.17886C3.40692 5.54352 3.70334 5.83722 4.0645 5.83722C4.42837 5.83722 4.72208 5.54352 4.72208 5.17886C4.72208 4.815 4.42837 4.52129 4.0645 4.52129C3.70334 4.52129 3.40692 4.815 3.40692 5.17886Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PlayIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.81055 12.4108C3.81055 13.0868 4.21111 13.4177 4.68975 13.4177C4.89653 13.4177 5.11395 13.3514 5.32691 13.2379L12.6353 8.96871C13.1678 8.66058 13.38 8.41966 13.38 8.04221C13.38 7.65974 13.1678 7.42388 12.6353 7.11464L5.32691 2.84655C5.11395 2.72689 4.89653 2.66675 4.68975 2.66675C4.21111 2.66675 3.81055 2.99653 3.81055 3.67366V12.4108Z"
        fill="currentColor"
      />
    </svg>
  );
}
