import { useMemo } from 'react';

import { Nullable } from '@/app/types';
import { useAccountStore } from '@/modules/accounts/store';
import { useAuthStore } from '@/modules/auth/store';
import { EmployeeType, RoleTypeOption } from '@/modules/roles/types';
import {
  CARS_ADVERTS_URL,
  RENT_ADVERTS_URL,
  VRP_ADVERTS_URL
} from '@/modules/showroom/advert/my/helpers';

export const PERMISSIONS_MAP = {
  analytics: {
    access: 1
  },
  carSale: {
    access: 2,
    control: 1,
    promo: 2
  },
  plateSale: {
    access: 15,
    control: 9,
    promo: 10
  },
  rent: {
    ads: {
      access: 3,
      control: 3,
      promo: 4
    },
    book: {
      access: 4,
      control: 5
    }
  },
  chat: {
    access: 5
  },
  callback: {
    access: 6
  },
  credit: {
    access: 7,
    control: 6
  },
  leads: {
    access: 8
  },
  wallet: {
    access: 9,
    control: 7
  },
  showroom: {
    info: {
      access: 10
    },
    addresses: {
      access: 11
    },
    contacts: {
      access: 12
    },
    notifications: {
      access: 16
    }
  },
  roles: {
    access: 13
  },
  reviews: {
    access: 14,
    control: 8
  },
  tariffs: {
    access: 17
  }
};

export const ROUTES_PERMISSIONS_MAP: Record<
  string,
  { options?: number[]; sub?: number[] }
> = {
  '/': {
    options: [PERMISSIONS_MAP.analytics.access]
  },
  '/cars/edit/': {
    sub: [PERMISSIONS_MAP.carSale.control]
  },
  '/vrp/edit/': {
    sub: [PERMISSIONS_MAP.plateSale.control]
  },
  '/cars/rent/edit/': {
    sub: [PERMISSIONS_MAP.rent.ads.control]
  },
  '/chat': {
    options: [PERMISSIONS_MAP.chat.access]
  },
  '/callback': {
    options: [PERMISSIONS_MAP.callback.access]
  },
  '/credits/requests': {
    options: [PERMISSIONS_MAP.credit.access]
  },
  '/leads': {
    options: [PERMISSIONS_MAP.leads.access]
  },
  [CARS_ADVERTS_URL]: {
    options: [PERMISSIONS_MAP.carSale.access]
  },
  [VRP_ADVERTS_URL]: {
    options: [PERMISSIONS_MAP.plateSale.access]
  },
  [RENT_ADVERTS_URL]: {
    options: [PERMISSIONS_MAP.rent.ads.access]
  },
  '/booking/rent': {
    options: [PERMISSIONS_MAP.rent.book.access]
  },
  '/showroom/general': {
    options: [PERMISSIONS_MAP.showroom.info.access]
  },
  '/showroom/address': {
    options: [PERMISSIONS_MAP.showroom.addresses.access]
  },
  '/showroom/contacts': {
    options: [PERMISSIONS_MAP.showroom.contacts.access]
  },
  '/showroom/reviews': {
    options: [PERMISSIONS_MAP.reviews.access]
  },
  '/showroom/roles': {
    options: [PERMISSIONS_MAP.roles.access]
  },
  '/showroom/notifications': {
    options: [PERMISSIONS_MAP.showroom.notifications.access]
  },
  '/payment/wallet': {
    options: [PERMISSIONS_MAP.wallet.access]
  },
  '/payment/topup': {
    options: [PERMISSIONS_MAP.wallet.control]
  },
  '/payment/tariffs': {
    options: [PERMISSIONS_MAP.tariffs.access]
  }
};

function getPermissionAccessObj(
  isOwner: boolean,
  optionsIds: number[],
  subOptionsIds: number[]
) {
  const check = (hasPermission: boolean) => {
    return isOwner || hasPermission;
  };

  return {
    wallet: {
      access: check(optionsIds.includes(PERMISSIONS_MAP.wallet.access)),
      control: check(subOptionsIds.includes(PERMISSIONS_MAP.wallet.control))
    },
    carSale: {
      access: check(optionsIds.includes(PERMISSIONS_MAP.carSale.access)),
      control: check(subOptionsIds.includes(PERMISSIONS_MAP.carSale.control)),
      promo: check(subOptionsIds.includes(PERMISSIONS_MAP.carSale.promo))
    },
    plateSale: {
      access: check(optionsIds.includes(PERMISSIONS_MAP.plateSale.access)),
      control: check(subOptionsIds.includes(PERMISSIONS_MAP.plateSale.control)),
      promo: check(subOptionsIds.includes(PERMISSIONS_MAP.plateSale.promo))
    },
    rent: {
      ads: {
        access: check(optionsIds.includes(PERMISSIONS_MAP.rent.ads.access)),
        control: check(
          subOptionsIds.includes(PERMISSIONS_MAP.rent.ads.control)
        ),
        promo: check(subOptionsIds.includes(PERMISSIONS_MAP.rent.ads.promo))
      },
      book: {
        access: check(optionsIds.includes(PERMISSIONS_MAP.rent.book.access)),
        control: check(
          subOptionsIds.includes(PERMISSIONS_MAP.rent.book.control)
        )
      }
    },
    credit: {
      access: check(optionsIds.includes(PERMISSIONS_MAP.credit.access)),
      control: check(subOptionsIds.includes(PERMISSIONS_MAP.credit.control))
    },
    reviews: {
      access: check(optionsIds.includes(PERMISSIONS_MAP.reviews.access)),
      control: check(subOptionsIds.includes(PERMISSIONS_MAP.reviews.control))
    },
    tariffs: {
      access: check(optionsIds.includes(PERMISSIONS_MAP.tariffs.access))
    },
    showroom: {
      addresses: {
        access: check(
          optionsIds.includes(PERMISSIONS_MAP.showroom.addresses.access)
        )
      }
    }
  };
}

export function hasPermissionAccess(employeeInfo: Nullable<EmployeeType>) {
  const auth = useAuthStore.getState().auth;
  const isOwner = !!auth?.is_owner;

  const permissions = employeeInfo?.permissions || [];

  const options = permissions.reduce(
    (acc, cur) => [...acc, ...cur.options],
    [] as RoleTypeOption[]
  );
  const optionsIds = options.map((opt) => opt.id);
  const subOptionsIds = options.reduce(
    (acc, cur) => [...acc, ...(cur.sub_options || []).map((v) => v.id)],
    [] as number[]
  );

  return getPermissionAccessObj(isOwner, optionsIds, subOptionsIds);
}

export function usePermissionAccess() {
  const auth = useAuthStore((s) => s.auth);
  const employeeInfo = useAccountStore((s) => s.employeeInfo);
  const permissions = useMemo(
    () => employeeInfo?.permissions || [],
    [employeeInfo?.permissions]
  );

  const options = useMemo(
    () =>
      permissions.reduce(
        (acc, cur) => [...acc, ...cur.options],
        [] as RoleTypeOption[]
      ),
    [permissions]
  );
  const optionsIds = useMemo(() => options.map((opt) => opt.id), [options]);
  const subOptionsIds = useMemo(
    () =>
      options.reduce(
        (acc, cur) => [...acc, ...(cur.sub_options || []).map((v) => v.id)],
        [] as number[]
      ),
    [options]
  );

  const isOwner = !!auth?.is_owner;
  const res = useMemo(
    () => getPermissionAccessObj(isOwner, optionsIds, subOptionsIds),
    [isOwner, optionsIds, subOptionsIds]
  );

  return res;
}
