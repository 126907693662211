'use client';

import { usePermissionAccess } from '@/modules/roles/helpers';
import { AddressWrap } from '@/modules/showroom/advert/update/AdditionalInfo/AddressWrap';
import { ComplectationWrap } from '@/modules/showroom/advert/update/AdditionalInfo/ComplectationWrap';
import { ConditionWrap } from '@/modules/showroom/advert/update/AdditionalInfo/ConditionWrap';
import { MileageWrap } from '@/modules/showroom/advert/update/AdditionalInfo/MileageWrap';
import { PromotionWrap } from '@/modules/showroom/advert/update/AdditionalInfo/PromotionWrap';
import { PublishResultWrap } from '@/modules/showroom/advert/update/AdditionalInfo/PublishResultWrap';
import { RegSpecsWrap } from '@/modules/showroom/advert/update/AdditionalInfo/RegSpecsWrap';
import { StickersWrap } from '@/modules/showroom/advert/update/AdditionalInfo/StickersWrap';
import { VinSectionWrap } from '@/modules/showroom/advert/update/AdditionalInfo/VinSectionWrap';
import { WarrantyWrap } from '@/modules/showroom/advert/update/AdditionalInfo/WarrantyWrap';
import { useAdvertUpdateFlags } from '@/modules/showroom/advert/update/hooks';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { SLUG_NEW } from '@/utils/consts';

import { DescriptionWrap } from './DescriptionWrap';
import { OwnerWrap } from './OwnerWrap';
import { PhoneWrap } from './PhoneWrap';
import { PhotosWrap } from './PhotosWrap';
import { PriceWrap } from './PriceWrap';
import { VideoWrap } from './VideoWrap';

export function AdditionalInfo() {
  const permissions = usePermissionAccess();
  const canEditPromo = permissions.carSale.promo;

  const { showPromo } = useAdvertUpdateFlags();
  const type = useUpdateAdvertStore((s) => s.type);
  const isNewCar = type?.slug === SLUG_NEW;

  const isBrokenFilled = useUpdateAdvertStore((s) => s.isBrokenFilled);
  const isMileageFilled = useUpdateAdvertStore((s) => s.isMileageFilled);
  const isPhotosFilled = useUpdateAdvertStore((s) => s.isPhotosFilled);
  const isVideoFilled = useUpdateAdvertStore((s) => s.isVideoFilled);
  const isComplectationFilled = useUpdateAdvertStore(
    (s) => s.isComplectationFilled
  );
  const isOwnerFilled = useUpdateAdvertStore((s) => s.isOwnerFilled);
  const filledOwner = isNewCar ? true : isOwnerFilled;

  const isRegSpecsFilled = useUpdateAdvertStore((s) => s.isRegSpecsFilled);
  const isWarrantyFilled = useUpdateAdvertStore((s) => s.isWarrantyFilled);
  const isDescriptionFilled = useUpdateAdvertStore(
    (s) => s.isDescriptionFilled
  );
  const isVinFilled = useUpdateAdvertStore((s) => s.isVinFilled);
  const isAddressFilled = useUpdateAdvertStore((s) => s.isAddressFilled);
  const isPhoneFilled = useUpdateAdvertStore((s) => s.isPhoneFilled);
  const isPriceFilled = useUpdateAdvertStore((s) => s.isPriceFilled);

  return (
    <>
      <ConditionWrap />

      {isBrokenFilled && (
        <>
          <MileageWrap />

          {isMileageFilled && (
            <>
              <PhotosWrap />

              {isPhotosFilled && (
                <>
                  <VideoWrap />

                  {isVideoFilled && (
                    <>
                      <ComplectationWrap />

                      {isComplectationFilled && (
                        <>
                          {!isNewCar && <OwnerWrap />}

                          {filledOwner && (
                            <>
                              <RegSpecsWrap />

                              {isRegSpecsFilled && (
                                <>
                                  <WarrantyWrap />

                                  {isWarrantyFilled && (
                                    <>
                                      <DescriptionWrap />

                                      {isDescriptionFilled && (
                                        <>
                                          <VinSectionWrap />

                                          {isVinFilled && (
                                            <>
                                              <AddressWrap />
                                              {isAddressFilled && (
                                                <>
                                                  <PhoneWrap />
                                                  {isPhoneFilled && (
                                                    <>
                                                      <PriceWrap />

                                                      {isPriceFilled &&
                                                        showPromo && (
                                                          <>
                                                            {canEditPromo && (
                                                              <>
                                                                <StickersWrap />
                                                                {permissions
                                                                  .tariffs
                                                                  .access && (
                                                                  <PromotionWrap />
                                                                )}
                                                              </>
                                                            )}
                                                            <PublishResultWrap />
                                                          </>
                                                        )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
