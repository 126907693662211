import { StateCreator, create } from 'zustand';

import { Nullable } from '@/app/types';
import { makeSpecsSlice } from '@/modules/showroom/advert/create/specs-slice';
import { makeCreateAdvertSlice } from '@/modules/showroom/advert/create/store-slice';
import {
  CreateAdvertStore,
  SpecStep
} from '@/modules/showroom/advert/create/types';
import { getAuthorDetail } from '@/modules/showroom/advert/detail/api';
import { AdvertAuthorDetail } from '@/modules/showroom/advert/detail/types';
import { updateAdvertisement } from '@/modules/showroom/advert/update/api';
import {
  getDetailFilledMaps,
  makeUpdateAdvertReq
} from '@/modules/showroom/advert/update/helpers';
import { showAlert } from '@/utils/network';

export interface UpdateAdvertStore {
  detail: Nullable<AdvertAuthorDetail>;
  handleDetail: (detail: AdvertAuthorDetail) => void;
  saveDetail: (id: number) => Promise<void>;
}

const makeUpdateAdvertStore: StateCreator<
  CreateAdvertStore & UpdateAdvertStore,
  [],
  [],
  UpdateAdvertStore
> = (set, get) => ({
  detail: null,
  handleDetail: (detail: AdvertAuthorDetail) => {
    const chatOnly =
      detail.available_phone === null ? false : !detail.available_phone;

    const complectationId = detail.complectation?.id || null;
    const complectationOptionsItemsIds = detail.complectation
      ? detail.complectation.complectation_options.reduce<number[]>(
          (acc, cat) => {
            const itemsIds = cat.options.reduce<number[]>((acc2, opt) => {
              return [...acc2, ...opt.items.map((item) => item.id)];
            }, []);

            return [...acc, ...itemsIds];
          },
          []
        )
      : [];

    const isCustomComplectation =
      !complectationId &&
      !detail.complectation?.name &&
      complectationOptionsItemsIds.length > 0;

    const warranty = detail.car_warranty?.expired_at || '';

    const { initialMap, newMap } = getDetailFilledMaps(detail);

    set({
      showPageLeaveWarn: true,
      detail,
      advertId: detail.id,
      complectationId,
      isCustomComplectation,
      complectationOptionsItemsIds,
      isComplectationFilled: newMap.isComplectationFilled,
      type: detail.type,
      vin: detail.vin || '',
      isVinSuggestFilled: detail.brand ? true : false,
      isVinFilled: newMap.isVinFilled,
      brand: detail.brand,
      model: detail.model,
      year: detail.year_of_issue,
      generation: detail.generation,
      body: detail.body,
      engine: detail.engine,
      drive: detail.drive_unit,
      transmission: detail.transmission,
      modification: detail.modification,
      color: detail.colour,
      isSpecsFilled: newMap.isSpecsFilled,
      step: initialMap.isSpecsFilled ? null : SpecStep.year,
      broken: !!detail.is_broken,
      isBrokenFilled: newMap.isBrokenFilled,
      mileage: detail.mileage || 0,
      isMileageFilled: newMap.isMileageFilled,
      photos: detail.photos
        ? detail.photos.map((p) => ({
            photo_url: p.photo_url,
            thumbnail_url: p.thumbnail_url,
            // todo: use medium_url
            medium_url: p.photo_url
          }))
        : [],
      isPhotosFilled: newMap.isPhotosFilled,
      videoUrl: detail.reels?.playlist_url || detail.reels?.source_url,
      isVideoFilled: newMap.isVideoFilled,
      owner: detail.owner,
      isOwnerFilled: newMap.isOwnerFilled,
      regSpecs: detail.regional_spec,
      isRegSpecsFilled: newMap.isRegSpecsFilled,
      warranty,
      isWarrantyFilled: newMap.isWarrantyFilled,
      description: detail.description || '',
      isDescriptionFilled: newMap.isDescriptionFilled,
      addressId: detail.address_id,
      isAddressFilled: newMap.isAddressFilled,
      chatOnly,
      disableChat:
        detail.available_chat === null ? false : !detail.available_chat,
      isPhoneFilled: newMap.isPhoneFilled,
      contacts:
        detail.communication_method
          ?.filter((c) => !!c.showroom_contact)
          ?.map((c) => ({
            id: c.showroom_contact?.id as number,
            communication_method: {
              phone: {
                available: !!c.available_phone,
                call_after: c.call_after,
                call_before: c.call_before
              },
              whatsapp: {
                available: !!c.available_whatsapp
              }
            }
          })) || [],
      canExchange: !!detail.available_trade,
      price: detail.price || 0,
      isPriceFilled: newMap.isPriceFilled,
      discount: detail.discount?.discount_amount || 0,
      discountTypes: detail.discount?.discounts?.map((d) => d.id) || [],
      stickers: detail.stickers?.map((s) => s.id) || []
    });
  },
  saveDetail: (id: number) => {
    return new Promise((resolve, reject) => {
      const state = get();

      getAuthorDetail(id).then((r) => {
        updateAdvertisement(id, {
          ...makeUpdateAdvertReq(state, r.data)
        })
          .then(() => resolve())
          .catch((error) => {
            showAlert({ error });
            reject();
          });
      });
    });
  }
});

export const useUpdateAdvertStore = create<
  CreateAdvertStore & UpdateAdvertStore
>()((...rest) => ({
  ...makeSpecsSlice(...rest),
  ...makeCreateAdvertSlice(...rest),
  ...makeUpdateAdvertStore(...rest)
}));
