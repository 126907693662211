import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLang } from '@/hooks/useLang';
import { createPaymentIntent } from '@/modules/payment/api';
import { PaymentTopUp } from '@/modules/payment/PaymentTopUp/PaymentTopUp';
import { AutoReplenishmentForm } from '@/modules/wallet/AutoReplenishment/AutoReplenishmentForm';
import { useAutoReplenishmentInfo } from '@/modules/wallet/AutoReplenishment/hooks';
import { Button } from '@/ui/Button/Button';
import { Input } from '@/ui/Input/Input';
import { AsideModal } from '@/ui/modals/AsideModal/AsideModal';
import { ADV_CUR } from '@/utils/consts';
import { numberWithSpaces, onlyNumbers } from '@/utils/format';
import { showAlert } from '@/utils/network';

import cls from './TopUpModal.module.scss';

const MIN_AMOUNT = 10;
const MAX_AMOUNT = 999999;

type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  initialAmount?: number;
  onConfirm?: () => void;
};

export function TopUpModal({
  isOpen,
  setOpen,
  initialAmount,
  onConfirm
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  const [replenishmentInfo, , loadReplenishmentInfo] = useAutoReplenishmentInfo(
    !isOpen
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>('');
  const [amount, setAmount] = useState<string>(
    initialAmount ? String(initialAmount) : ''
  );
  const [amountTouched, setAmountTouched] = useState<boolean>(false);

  useEffect(() => {
    if (initialAmount && isOpen) {
      setAmount(String(initialAmount));
    }
  }, [initialAmount, isOpen]);

  const isAmountValid =
    !!amount && amount.length >= 2 && Number(amount) >= MIN_AMOUNT;

  useEffect(() => {
    if (!isOpen) {
      setLoading(false);
      setAmount('');
      setAmountTouched(false);
      setClientSecret('');
    }
  }, [isOpen]);

  const onAmountChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = onlyNumbers(e.currentTarget.value);

    if (Number(value) > MAX_AMOUNT) {
      setAmount(MAX_AMOUNT.toString());
      return;
    }

    setAmount(value);
  };

  const onTopUpClick = () => {
    const amountNum = Number(amount) * 100;

    setLoading(true);
    createPaymentIntent({
      amount: amountNum,
      currency: ADV_CUR
    })
      .then(({ data }) => {
        setClientSecret(data.clientSecret);
      })
      .catch((error) => showAlert({ error }))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <AsideModal
        name="balance-top-up"
        isOpen={isOpen}
        close={() => setOpen(false)}
        title={t('tariffs.topUp.title')}
        containerClass={cls.modal_container}
      >
        <div className={cls.root}>
          {clientSecret ? (
            <PaymentTopUp clientSecret={clientSecret} onConfirm={onConfirm} />
          ) : (
            <div className={cls.topup}>
              <div>
                <Input
                  label={t('tariffs.topUp.amountLabel', { currency: ADV_CUR })}
                  value={amount ? numberWithSpaces(Number(amount), lang) : ''}
                  onChange={onAmountChange}
                  onBlur={() => setAmountTouched(true)}
                  disabled={loading}
                  inputMode="numeric"
                  errorText={
                    amountTouched && !isAmountValid
                      ? t('tariffs.topUp.minAmount', {
                          min: MIN_AMOUNT,
                          currency: ADV_CUR
                        })
                      : ''
                  }
                />
                <p className={cls.label}>
                  {t('tariffs.topUp.restrictions', {
                    min: MIN_AMOUNT,
                    max: numberWithSpaces(MAX_AMOUNT, lang),
                    currency: ADV_CUR
                  })}
                </p>
              </div>
              <Button
                color="green"
                onClick={onTopUpClick}
                disabled={!isAmountValid || loading}
                loading={loading}
                fullWidth
              >
                {t('tariffs.topUp.action')}
              </Button>

              {replenishmentInfo && (
                <AutoReplenishmentForm
                  replenishmentInfo={replenishmentInfo}
                  updateInfo={loadReplenishmentInfo}
                  autoSave={isOpen}
                />
              )}
            </div>
          )}
        </div>
      </AsideModal>
    </>
  );
}
