import { isPhoneValid } from '@/modules/showroom/advert/create/helpers';
import {
  CreateAdvertSlice,
  SpecsSlice
} from '@/modules/showroom/advert/create/types';
import { RentAdvertAuthorDetail } from '@/modules/showroom/advert/rent/detail/types';
import { optimizeRentReq } from '@/modules/showroom/advert/rent/update/compare-req';
import {
  DEPOSIT_DAYS_MAX_LEN,
  DEPOSIT_DAYS_MIN_LEN,
  DEPOSIT_MAX_LEN,
  DEPOSIT_MIN_LEN,
  MAX_DRIVE_EXP,
  MIN_AGE,
  TARIFF_PRICE_MAX_LEN,
  TARIFF_PRICE_MIN_LEN
} from '@/modules/showroom/advert/rent/update/consts';
import { RentUpdateAdvertStore } from '@/modules/showroom/advert/rent/update/store-type';
import { UpdateRentAdvertReq } from '@/modules/showroom/advert/rent/update/types';

export function makeRentUpdateAdvertReq(
  store: CreateAdvertSlice & SpecsSlice & RentUpdateAdvertStore,
  detail: RentAdvertAuthorDetail
): Omit<UpdateRentAdvertReq, 'status_id'> {
  return optimizeRentReq(store, detail);
}

export function getRentDetailFilledMaps(detail: RentAdvertAuthorDetail) {
  const complectationId = detail.complectation?.id || null;
  const complectationOptionsItemsIds = detail.complectation
    ? detail.complectation.complectation_options.reduce<number[]>(
        (acc, cat) => {
          const itemsIds = cat.options.reduce<number[]>((acc2, opt) => {
            return [...acc2, ...opt.items.map((item) => item.id)];
          }, []);

          return [...acc, ...itemsIds];
        },
        []
      )
    : [];

  const initialMap: Record<string, boolean> = {
    isSpecsFilled: !!detail.body_colour && !!detail.interior_colour,
    isPhotosFilled: !!detail.photos && detail.photos.length > 0,
    isComplectationFilled:
      !!complectationId || complectationOptionsItemsIds.length > 0,
    isDescriptionFilled: !!detail.description,
    isAddressFilled: !!detail.address,
    isPhoneFilled: isPhoneValid(detail.communication_method),
    isDepositFilled:
      detail.deposit !== null && detail.deposit_return_days !== null,
    isAgeFilled: detail.age !== null && detail.driving_experience !== null,
    isPayMethodIdsFilled: !!detail.pay_methods && detail.pay_methods.length > 0,
    isDocsIdsFilled: !!detail.docs && detail.docs.length > 0,
    isTariffsFilled: !!detail.tariffs && detail.tariffs.length > 0
  };

  const newMap: Record<string, boolean> = { ...initialMap };
  const keys = Object.keys(initialMap);
  keys.forEach((key, i) => {
    const nextValues = keys.slice(i);
    const someNextFilled = nextValues.some((k) => initialMap[k]);
    newMap[key] = initialMap[key] || someNextFilled;
  });

  return { initialMap, newMap };
}

interface SortTariffType {
  days_from: number;
}
export function sortTariffs<T extends SortTariffType>(tariffs: T[]): T[] {
  return [...tariffs].sort((a, b) => {
    return a.days_from - b.days_from;
  });
}

export function isDepositValid(
  deposit: RentUpdateAdvertStore['deposit'],
  depositReturnDays: RentUpdateAdvertStore['depositReturnDays']
): boolean {
  const depStr = deposit?.toString() || '';
  const daysStr = depositReturnDays?.toString() || '';
  const depositValid =
    !!deposit &&
    depStr.length >= DEPOSIT_MIN_LEN &&
    depStr.length <= DEPOSIT_MAX_LEN;
  const depositDaysValid =
    !!depositReturnDays &&
    daysStr.length >= DEPOSIT_DAYS_MIN_LEN &&
    daysStr.length <= DEPOSIT_DAYS_MAX_LEN;
  return depositValid && depositDaysValid;
}

export function isAgeValid(
  age: RentUpdateAdvertStore['age'],
  driveExp: RentUpdateAdvertStore['driveExp']
): boolean {
  return (
    !!age &&
    !!driveExp &&
    age >= MIN_AGE &&
    driveExp < age &&
    age - driveExp >= MIN_AGE - 1 &&
    driveExp < MAX_DRIVE_EXP
  );
}

export function isPayMethodsValid(
  payMethodsIds: RentUpdateAdvertStore['payMethodsIds']
): boolean {
  return payMethodsIds.length > 0;
}

export function isTariffsValid(
  tariffs: RentUpdateAdvertStore['tariffs']
): boolean {
  return (
    tariffs.length > 0 &&
    tariffs.every((tf) => {
      const str = tf.price.toString();
      return (
        !!tf.price &&
        str.length >= TARIFF_PRICE_MIN_LEN &&
        str.length <= TARIFF_PRICE_MAX_LEN
      );
    })
  );
}
