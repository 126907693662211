import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Nullable, OptionI } from '@/app/types';
import { useLang } from '@/hooks/useLang';
import {
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { Button } from '@/ui/Button/Button';
import { Select } from '@/ui/Select/Select';
import { capitalizeWord } from '@/utils/format';

import cls from './Warranty.module.scss';

const currentYear = new Date().getFullYear();

type Props = {
  warrantyDate: CreateAdvertSlice['warranty'];
  setWarrantyDate: CreateAdvertSlice['setWarranty'];
  isWarrantyFilled: CreateAdvertSlice['isWarrantyFilled'];
  setWarrantyFilled: CreateAdvertSlice['setWarrantyFilled'];
};

export function Warranty({
  warrantyDate,
  setWarrantyDate,
  isWarrantyFilled,
  setWarrantyFilled
}: Props) {
  const { t } = useTranslation();
  const [lang] = useLang();

  // Month
  const [month, setMonth] = useState<Nullable<number>>(null);
  const monthOptions = useMemo<OptionI[]>(() => {
    const arr: OptionI[] = [];

    for (let i = 0; i < 12; i++) {
      const d = new Date(new Date().getFullYear(), i, 1);
      const text = capitalizeWord(
        d.toLocaleDateString(lang, { month: 'long' })
      );
      arr.push({ id: String(i), text });
    }

    return arr;
  }, [lang]);
  const monthOption = monthOptions.find((opt) => opt.id === String(month));

  // Year
  const [year, setYear] = useState<Nullable<number>>(null);
  const yearOptions = useMemo<OptionI[]>(() => {
    const arr: OptionI[] = [];

    for (let i = currentYear; i < currentYear + 11; i++) {
      const str = String(i);
      arr.push({ id: str, text: str });
    }

    return arr;
  }, []);
  const yearOption = yearOptions.find((opt) => opt.id === String(year));

  useEffect(() => {
    if (typeof month === 'number' && typeof year === 'number') {
      const m = month + 1;
      setWarrantyDate(`${year}-${m < 10 ? '0' : ''}${m}-01`);
    }
  }, [month, setWarrantyDate, year]);

  useEffect(() => {
    if (warrantyDate && typeof month !== 'number' && typeof year !== 'number') {
      const [y, m] = warrantyDate.split('-');
      const month = Number(m) - 1;
      const year = Number(y);
      setMonth(!isNaN(month) ? month : null);
      setYear(!isNaN(year) ? year : null);
    }
  }, [month, warrantyDate, year]);

  const [expired, setExpired] = useState(false);
  const onExpiredClick = () => {
    setExpired(true);
    setMonth(null);
    setYear(null);
    setWarrantyDate('');
    setWarrantyFilled(true);
    scrollToSection(SCROLLTO.descr, 100);
  };

  const undoExpire = () => {
    setExpired(false);
    setMonth(null);
    setYear(null);
    setWarrantyDate('');
  };

  useEffect(() => {
    if (warrantyDate && expired) {
      setExpired(false);
    }
  }, [expired, warrantyDate]);

  const onClick = () => {
    setWarrantyFilled(true);
    scrollToSection(SCROLLTO.descr, 100);
  };

  return (
    <div className={cls.root} id={SCROLLTO.warranty}>
      <div className="box">
        <h1 className={cls.title}>
          {expired ? (
            <Trans
              t={t}
              i18nKey="advertisements.warranty.titleRich"
              components={{
                wrap: <span />
              }}
              values={{ value: t('advertisements.warranty.end') }}
            />
          ) : (
            t('advertisements.warranty.title')
          )}
        </h1>
        {!expired && (
          <p className={cls.subtitle}>{t('advertisements.warranty.tip')}</p>
        )}

        <div className={cls.row} style={{ display: expired ? 'none' : 'flex' }}>
          <Select
            name="warranty-year"
            label={t('yearOnEnd')}
            onChange={(o) => setYear(Number(o.id))}
            value={yearOption}
            options={yearOptions}
            dropdownTop={!isWarrantyFilled}
          />
          <Select
            name="warranty-month"
            label={t('common.month')}
            onChange={(o) => setMonth(Number(o.id))}
            value={monthOption}
            options={monthOptions}
            dropdownTop={!isWarrantyFilled}
          />
        </div>

        <div className={cls.buttons}>
          {expired ? (
            <Button
              onClick={undoExpire}
              color="black"
              variant="secondary"
              fullWidth
            >
              {t('advertisements.warranty.undoExpire')}
            </Button>
          ) : (
            <Button
              onClick={onExpiredClick}
              color="black"
              variant="secondary"
              fullWidth
            >
              {t('advertisements.warranty.ended')}
            </Button>
          )}

          {!isWarrantyFilled && warrantyDate && (
            <Button onClick={onClick} color="blue" variant="primary" fullWidth>
              {t('common.continue')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
