import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@/ui/Skeleton';

import cls from './CallbackRow.module.scss';

export function CallbackRowSkeleton() {
  const { t } = useTranslation();

  return (
    <div className={cls.root} style={{ marginBottom: '8px' }}>
      <div className={cls.col}>
        <div className={cls.img}>
          <Skeleton className={cls.img_inner} />
        </div>

        <div className={cn(cls.mob_ad, cls.hide_desktop)}>
          <p className={cls.advert}>
            <Skeleton width={180} height={18} />
          </p>
          <p className={cls.price}>
            <Skeleton width={130} height={18} />
          </p>

          <p className={cls.engine}>
            <Skeleton width={100} height={18} />
          </p>
        </div>
      </div>

      <div className={cn(cls.col, cls.hide_mob)}>
        <div className={cls.advert_wrap}>
          <p className={cls.advert}>
            <Skeleton width={180} height={24} />
          </p>
        </div>
        <Skeleton width={320} height={20} />
        <div className={cls.date}>
          <Skeleton width={160} height={20} />
        </div>
      </div>

      <div className={cls.col}>
        <p className={cls.col_label}>{t('credits.req.aboutLead')}</p>

        <div className={cn(cls.contact, cls.hide_mob)}>
          <Skeleton width={124} height={20} />
        </div>
        <div className={cn(cls.contact, cls.hide_mob)}>
          <Skeleton width={124} height={20} />
        </div>
        <div className={cls.contact}>
          <Skeleton width={124} height={20} />
        </div>
      </div>

      <div className={cn(cls.mob_contact, cls.hide_desktop)}>
        <Skeleton width={36} height={36} />
        <div className={cls.mob_footer}>
          <p className={cls.customer_name}>
            <Skeleton width={150} height={20} />
          </p>
          <p className={cls.date}>
            <Skeleton width={120} height={18} />
          </p>
        </div>
      </div>
    </div>
  );
}
