import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { updateShowroom, useShowroom } from '@/modules/showroom/api';
import { updateShowroomContact } from '@/modules/showroom/contact/api';
import { useShowroomDataValidate } from '@/modules/showroom/CreateShowroom/hooks';
import {
  ShowroomEdit,
  ShowroomEditValues
} from '@/modules/showroom/ShowroomEdit/ShowroomEdit';
import { LayoutHeader } from '@/ui/layouts/SidebarLayout/LayoutHeader';
import { PageHead } from '@/ui/PageHead/PageHead';
import { Spinner } from '@/ui/Spinner/Spinner';
import { showAlert } from '@/utils/network';

import cls from './UpdateShowroom.module.scss';

export function UpdateShowroom() {
  const { t } = useTranslation();

  const { validate } = useShowroomDataValidate();
  const [showroom, showroomLoading, fetchShowroom] = useShowroom();
  useEffect(() => {
    fetchShowroom();
  }, [fetchShowroom]);

  const showroomValues = useMemo<Nullable<ShowroomEditValues>>(() => {
    if (showroom) {
      return {
        photos: showroom.photos || [],
        description: showroom.description || '',
        siteUrl: showroom.site_url || '',
        name: showroom.name || '',
        username: showroom.username || '',
        coverUrl: showroom.cover || '',
        logoUrl: showroom.logo_url || '',
        contactName: showroom.contact.name || '',
        contactPhone: showroom.contact.phone || '',
        contactEmail: showroom.contact.email || ''
      };
    }

    return null;
  }, [showroom]);

  const [isLoading, setLoading] = useState(false);
  const onClick = async (values: ShowroomEditValues) => {
    const isDataValid = validate(values);
    if (!isDataValid || !showroom) return;

    setLoading(true);

    const isSameUsername = !!showroom && showroom.username === values.username;
    const isSameContactPhone =
      !!showroom && showroom.contact.phone === values.contactPhone;

    try {
      await updateShowroom({
        description: values.description,
        site_url: values.siteUrl,
        photos: values.photos.length ? values.photos : [],
        name: values.name,
        username: isSameUsername ? undefined : values.username,
        logo_url: values.logoUrl,
        cover: values.coverUrl || ''
      });
      await updateShowroomContact({
        contact_id: showroom.contact.id,
        name: values.contactName,
        phone: isSameContactPhone ? undefined : values.contactPhone
      });
      showAlert({ type: 'success', text: t('showroom.saved') });
      fetchShowroom();
    } catch (error) {
      showAlert({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={cls.root}>
      <LayoutHeader title={t('general')} />
      <PageHead title={t('pageTitle', { title: t('general') })} />

      {showroomValues ? (
        <ShowroomEdit
          onClick={onClick}
          loading={isLoading || showroomLoading}
          buttonText={t('common.save')}
          initialValues={showroomValues}
          setLoading={setLoading}
        />
      ) : (
        <Spinner centered />
      )}
    </div>
  );
}
