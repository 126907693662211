import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Nullable } from '@/app/types';
import { UploadRes } from '@/modules/files/types';
import {
  getImageFromVideoURL,
  ThumbnailType
} from '@/modules/files/upload/helpers';
import { UploadVideo } from '@/modules/files/upload/UploadVideo/UploadVideo';
import { ReelPlayer } from '@/modules/reels/ReelPlayer/ReelPlayer';
import { isReelVideoPlaylist } from '@/modules/showroom/advert/update/helpers';
import { Button } from '@/ui/Button/Button';

import { DeleteIcon } from './icons';
import cls from './Video.module.scss';

import { SCROLLTO, scrollToSection } from '../helpers';

type Props = {
  videoUrl: Nullable<string>;
  setVideoUrl: (videoUrl: Nullable<string>) => void;
  isVideoFilled: boolean;
  setVideoFilled: (v: boolean) => void;
};

export function Video({
  videoUrl,
  setVideoUrl,
  isVideoFilled,
  setVideoFilled
}: Props) {
  const { t } = useTranslation();

  const isVideoPlaylist = !!videoUrl && isReelVideoPlaylist(videoUrl);

  const [isUploaded, setUploaded] = useState(!!videoUrl);
  const [posterUrl, setPosterUrl] = useState('');
  const [posterImg, setPosterImg] = useState<Nullable<File>>(null);
  const [reelsVideoType, setReelsVideoType] = useState('');

  const handleUpload = (
    uploadResult: UploadRes,
    uploadedFile: File,
    _thumbnail: Nullable<ThumbnailType>,
    poster: Nullable<ThumbnailType>
  ) => {
    if (poster?.r?.url) setPosterUrl(poster.r.url);
    if (uploadResult?.url && uploadedFile?.type) {
      setUploaded(true);
      setVideoUrl(uploadResult.url);
      uploadedFile?.type && setReelsVideoType(uploadedFile.type);
    }
  };

  const handleRemoveClick = () => {
    setUploaded(false);
    setVideoUrl(null);
    setReelsVideoType('');
    setPosterUrl('');
    setPosterImg(null);
  };

  const onButtonClick = () => {
    setVideoFilled(true);
    scrollToSection(SCROLLTO.complectation, 100);
  };

  const getPosterImg = async () => {
    if (!videoUrl) return;

    try {
      const posterImgFromVideo = await getImageFromVideoURL(videoUrl);
      if (posterImgFromVideo) setPosterImg(posterImgFromVideo);
    } catch (error) {
      console.error('Error getting poster from video URL', error);
    }
  };

  useEffect(() => {
    if (!!videoUrl && !isVideoPlaylist && !posterUrl) {
      getPosterImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUrl, posterUrl, isVideoPlaylist]);

  useEffect(() => {
    if (posterImg) {
      const objectUrl = URL.createObjectURL(posterImg);
      setPosterUrl(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [posterImg]);

  return (
    <div className={cls.root} id={SCROLLTO.video}>
      <div className="box">
        <div className={cls.header}>
          <h1 className={cls.title}>{t('upload.video.title')}</h1>
          <p className={cls.label}>{t('common.new')}</p>
        </div>

        <div className={cls.box}>
          <div className={cls.upload_container}>
            {!isUploaded && <UploadVideo onUpload={handleUpload} />}

            {isVideoPlaylist ? (
              <>
                <button
                  onClick={handleRemoveClick}
                  className={cn(cls.remove_btn, cls.remove_btn_reel)}
                >
                  <DeleteIcon />
                </button>

                <ReelPlayer
                  reel={{ playlist_url: videoUrl }}
                  autoPlay={false}
                  initial
                />
              </>
            ) : (
              <>
                {isUploaded && videoUrl && (
                  <>
                    <button
                      onClick={handleRemoveClick}
                      className={cls.remove_btn}
                    >
                      <DeleteIcon />
                    </button>
                    <div
                      className={cls.poster}
                      style={{ backgroundImage: `url(${posterUrl})` }}
                    />
                    <video
                      controls
                      autoPlay
                      muted
                      loop
                      disablePictureInPicture
                      playsInline
                      className={cls.poster_img}
                    >
                      <source type={reelsVideoType} src={videoUrl} />
                    </video>
                  </>
                )}
              </>
            )}
          </div>
          <div className={cls.description}>
            <p className={cls.desc_title}>{t('upload.video.attention')}</p>
            <p className={cls.info}>
              <Trans t={t} i18nKey={'upload.video.playInfo'} />
            </p>
            <p className={cls.info}>
              <Trans t={t} i18nKey={'upload.video.showInReels'} />
            </p>
            <p className={cls.info}>
              <Trans
                t={t}
                i18nKey={'upload.video.format'}
                components={{ b: <b /> }}
              />
            </p>
          </div>
        </div>

        {!isVideoFilled && (
          <div className={cls.btn}>
            <Button
              variant={isUploaded ? 'primary' : 'secondary'}
              color={isUploaded ? 'blue' : 'black'}
              onClick={onButtonClick}
              fullWidth
            >
              {t(isUploaded ? 'common.continue' : 'common.skip')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
