import { DiscountType } from '@/modules/discount/types';
import { CreateAdvertSlice } from '@/modules/showroom/advert/create/types';
import { CommunicationMethod } from '@/modules/showroom/advert/detail/types';
import { HEADER_ID } from '@/ui/layouts/BaseLayout/BaseLayout';

export const MAX_MILEAGE_LEN = 7;
export const MAX_PHOTOS_LEN = 40;
export const MAX_DESCR_TITLE_LEN = 200;
export const MAX_DESCR_LEN = 5000;
export const MIN_PRICE = 1000;
export const MIN_PRICE_LEN = 4;
export const MAX_PRICE_LEN = 8;
export const MIN_TIME_TO_100 = 1;
export const MAX_TIME_TO_100 = 50;
export const MIN_HORSEPOWER = 0;
export const MAX_HORSEPOWER = 10000;
export const MIN_VOLUME = 0;
export const MAX_VOLUME = 10;
export const DEFAULT_CALL_AFTER_HOURS = 9;
export const DEFAULT_CALL_AFTER_MINUTES = DEFAULT_CALL_AFTER_HOURS * 60;
export const DEFAULT_CALL_BEFORE_HOURS = 20;
export const DEFAULT_CALL_BEFORE_MINUTES = DEFAULT_CALL_BEFORE_HOURS * 60;
export const MAX_STICKERS_LEN = 3;
export const MIN_VIN_LEN = 10;
export const MAX_VIN_LEN = 17;
export const NO_PROMO_ID = 4;

export const SCROLLTO = {
  specs: 'scroll-to-specs',
  condition: 'scroll-to-condition',
  booking: 'scroll-to-booking',
  mileage: 'scroll-to-mileage',
  photo: 'scroll-to-photo',
  owner: 'scroll-to-owner',
  warranty: 'scroll-to-warranty',
  descr: 'scroll-to-descr',
  regspecs: 'scroll-to-regspecs',
  vin: 'scroll-to-vin',
  showroom: 'scroll-to-showroom',
  phone: 'scroll-to-phone',
  contacts: 'scroll-to-contacts',
  stickers: 'scroll-to-stickers',
  price: 'scroll-to-price',
  plate: 'scroll-to-plate',
  complectation: 'scroll-to-complectation',
  promotion: 'scroll-to-promotion',
  deposit: 'scroll-to-deposit',
  age: 'scroll-to-age',
  payMethods: 'scroll-to-payMethods',
  tariffs: 'scroll-to-tariffs',
  docs: 'scroll-to-docs',
  video: 'scroll-to-video'
};

export function scrollToBottom() {
  window.setTimeout(() => {
    window.scrollTo({ top: window.scrollY + 400, behavior: 'smooth' });
  }, 100);
}

export function scrollToSection(id: string, delay?: number) {
  window.setTimeout(() => {
    const el = document.getElementById(id);
    if (!el) return;

    const header = document.getElementById(HEADER_ID);
    const headerH = header ? header.offsetHeight : 0;

    const top = el.getBoundingClientRect().top + window.pageYOffset - headerH;
    window.scrollTo({ top, behavior: 'smooth' });
  }, delay || 0);
}

export function isPriceValid(price: number): boolean {
  return price >= MIN_PRICE && String(price).length >= MIN_PRICE_LEN;
}

export function isVinValid(vin: string): boolean {
  const len = vin.length;
  return len >= MIN_VIN_LEN && vin.length <= MAX_VIN_LEN;
}

export function isDiscountsValid(
  price: number,
  discountAmount: number,
  discountTypes: number[] | DiscountType[]
): boolean {
  const isAmountValid = discountAmount <= price;
  const isTypesValid = discountTypes.length > 0 ? !!discountAmount : true;
  return isAmountValid && isTypesValid;
}

export function isPhoneValid(
  contacts: CreateAdvertSlice['contacts'] | CommunicationMethod[]
): boolean {
  return !!contacts && contacts.length > 0;
}
