import { Video } from '../../create/Video/Video';
import { useUpdateAdvertStore } from '../store';

export function VideoWrap() {
  const videoUrl = useUpdateAdvertStore((s) => s.videoUrl);
  const setVideoUrl = useUpdateAdvertStore((s) => s.setVideoUrl);
  const isVideoFilled = useUpdateAdvertStore((s) => s.isVideoFilled);
  const setVideoFilled = useUpdateAdvertStore((s) => s.setVideoFilled);

  return (
    <Video
      videoUrl={videoUrl}
      setVideoUrl={setVideoUrl}
      isVideoFilled={isVideoFilled}
      setVideoFilled={setVideoFilled}
    />
  );
}
