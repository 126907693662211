/* eslint-disable no-async-promise-executor */
import imageCompression from 'browser-image-compression';
import VideoSnapshot from 'video-snapshot-fixed';

import { Nullable } from '@/app/types';
import { uploadFile, setWatermark } from '@/modules/files/api';
import { UploadCfRes, UploadRes } from '@/modules/files/types';

// Extensions
const imgExtensions = ['png', 'jpg', 'jpeg', 'pjpeg', 'gif', 'heif', 'heic'];
const videoExtensions = ['mp4', 'mov', 'm4v'];
const fileExtensions = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'txt',
  'html'
];

export const acceptImages = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'image/heic': ['.heic']
};

export const acceptVideos = {
  'video/mp4': ['.mp4'],
  'video/mov': ['.mov']
};

export const acceptMap = {
  video: 'video/*',
  image: imgExtensions.map((d) => `.${d}`).join(','),
  file: fileExtensions.map((d) => `.${d}`).join(','),
  media: [...imgExtensions, ...videoExtensions].map((d) => `.${d}`).join(',')
};

// Types
export type UploadAcceptType = 'image' | 'video' | 'file' | 'media';
export type ThumbnailType = { r: UploadRes; f: File };
export type UploadItem = {
  response: UploadRes;
  file: File;
  thumbnail: Nullable<ThumbnailType>;
  poster: Nullable<ThumbnailType>;
  duration?: Nullable<number>;
};

export function mapCfImageUpload(
  data: UploadCfRes[],
  files: File[]
): UploadItem[] {
  return data.map((v, i) => {
    return {
      response: {
        url: v.variants.public || '',
        extension: v.extension,
        name: v.name,
        size: v.size
      },
      file: files[i],
      thumbnail: v.variants.thumbnail
        ? {
            r: {
              url: v.variants.thumbnail,
              extension: v.extension,
              name: v.name,
              size: v.size
            },
            f: files[i]
          }
        : null,
      poster: null
    };
  });
}

export const MAX_THUMB_SIZE = 900;
export const compressOptions = {
  maxSizeMB: 10,
  useWebWorker: true
};

// Image handler
type HandleImageParams = {
  file: File;
  imgSize?: number;
  watermark?: boolean;
};

export function handleImage({
  file,
  imgSize,
  watermark
}: HandleImageParams): Promise<UploadItem> {
  return new Promise<UploadItem>(async (resolve, reject) => {
    try {
      const imgCompressOpts = imgSize
        ? { ...compressOptions, maxWidthOrHeight: imgSize, initialQuality: 0.9 }
        : compressOptions;
      const image = await imageCompression(file, imgCompressOpts);

      let finalImg = image;
      if (watermark) {
        const watermarkRes = await setWatermark(image);
        const imgWithWatermark = new File([watermarkRes.data], file.name, {
          type: file.type
        });
        finalImg = imgWithWatermark;
      }

      const imageResponse = await uploadFile({ file: finalImg });

      // Thumbnail
      // let thumb = null;
      // if (thumbnail) {
      //   const compressedThumb = await imageCompression(file, {
      //     ...compressOptions,
      //     maxWidthOrHeight: thumbSize || MAX_THUMB_SIZE,
      //     initialQuality: 0.9
      //   });

      //   const thumbResponse = await uploadFile({ file: compressedThumb });
      //   thumb = { r: thumbResponse.data, f: compressedThumb };
      // }

      resolve({
        file: image,
        response: imageResponse.data,
        thumbnail: { r: imageResponse.data, f: image },
        poster: null
      });
    } catch (error) {
      reject(error);
    }
  });
}

// Video handler
export function handleVideo(
  f: File,
  videoPoster: boolean | undefined
): Promise<UploadItem> {
  return new Promise<UploadItem>(async (resolve, reject) => {
    try {
      // Video poster
      let poster = null;
      if (videoPoster) {
        const posterFile = await getImageFromVideo(f);
        if (posterFile) {
          const compressedPoster = await imageCompression(posterFile, {
            ...compressOptions,
            maxWidthOrHeight: MAX_THUMB_SIZE
          });
          const posterResponse = await uploadFile({ file: compressedPoster });
          poster = { r: posterResponse.data, f: compressedPoster };
        }
      }

      // Video
      const r = await uploadFile({ file: f }, f.type);
      const duration = await getVideoDuration(f);
      resolve({
        duration,
        poster,
        file: f,
        response: handleUploadResponse(r.data),
        thumbnail: null
      });
    } catch (error) {
      reject(error);
    }
  });
}

// Doc handler
export function handleDoc(f: File): Promise<UploadItem> {
  return new Promise<UploadItem>(async (resolve, reject) => {
    try {
      const { data } = await uploadFile({ file: f });
      resolve({
        response: data,
        file: f,
        thumbnail: null,
        poster: null
      });
    } catch (error) {
      reject(error);
    }
  });
}

// Helper funcs
export function getImageFromVideo(videoFile: File): Promise<Nullable<File>> {
  return new Promise<Nullable<File>>((resolve, reject) => {
    const snapshoter = new VideoSnapshot(videoFile);
    snapshoter
      .takeSnapshot()
      .then(async (previewSrc) => {
        try {
          const res = await fetch(previewSrc);
          const blob = await res.blob();
          const file = new File([blob], `${videoFile.name.split('.')[0]}.png`, {
            type: 'image/png'
          });

          resolve(file);
        } catch (error) {
          reject(error);
        }
      })
      .catch(reject);
  });
}

export function getImageFromVideoURL(
  videoUrl: string
): Promise<Nullable<File>> {
  return new Promise<Nullable<File>>((resolve, reject) => {
    fetch(videoUrl)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch video: ${response.statusText}`);
        }
        const blob = await response.blob();
        const videoFile = new File([blob], `video_${Date.now()}.mp4`, {
          type: blob.type
        });

        const snapshoter = new VideoSnapshot(videoFile);
        snapshoter
          .takeSnapshot()
          .then(async (previewSrc) => {
            try {
              const res = await fetch(previewSrc);
              const imageBlob = await res.blob();
              const file = new File([imageBlob], `snapshot_${Date.now()}.png`, {
                type: 'image/png'
              });

              resolve(file);
            } catch (error) {
              reject(error);
            }
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

export function getImageUrlFromFile(
  file: File
): Promise<string | ArrayBuffer | null> {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.readAsDataURL(file);
  });
}

export const getVideoDuration = async (f: File): Promise<number> => {
  const fileCallbackToPromise = (el: HTMLElement) => {
    return Promise.race([
      new Promise((resolve) => {
        // eslint-disable-next-line no-param-reassign
        if (el instanceof HTMLImageElement) el.onload = resolve;
        // eslint-disable-next-line no-param-reassign
        else el.onloadedmetadata = resolve;
      }),
      new Promise((_, reject) => {
        setTimeout(reject, 1000);
      })
    ]);
  };

  const objectUrl = URL.createObjectURL(f);
  const video = document.createElement('video');
  video.src = objectUrl;
  await fileCallbackToPromise(video);
  return video.duration;
};

export function handleUploadResponse(res: UploadRes): UploadRes {
  return {
    ...res,
    extension: res.extension.includes('/')
      ? res.extension.split('/')[1]
      : res.extension
  };
}
