import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDesktop } from '@/app/common-store';
import { usePermissionAccess } from '@/modules/roles/helpers';
import { CategoryDropdown } from '@/modules/showroom/advert/create/CategoryDropdown/CategoryDropdown';
import { useAdvertCreation } from '@/modules/showroom/advert/create/hooks';
import { VRP_CATEGORY_ID } from '@/modules/showroom/advert/vrp/helpers';
import { Placeholder } from '@/ui/Placeholder/Placeholder';

import emptyImg from './empty.png';
import cls from './SearchResult.module.scss';

type Props = {
  onResetClick: () => void;
  categoryId: number;
  withRoot?: boolean;
  noPublished?: boolean;
};

const SearchResultEmptyComponent = ({
  onResetClick,
  withRoot,
  categoryId,
  noPublished
}: Props) => {
  const { t } = useTranslation();
  const isDesktop = useDesktop();

  const isPlate = categoryId === VRP_CATEGORY_ID;
  const { createPlate } = useAdvertCreation();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const permissions = usePermissionAccess();
  const showAdCreate =
    permissions.carSale.control ||
    permissions.plateSale.control ||
    permissions.rent.ads.control;

  const onPlaceholderBtnClick = () => {
    if (isPlate) {
      createPlate();
    } else {
      setDropdownOpen(true);
    }
  };

  const noPublishedView = (
    <div className={cls.empty}>
      <Placeholder
        imgSrc={emptyImg}
        title={t('advertisements.noPublished')}
        compact={!isDesktop}
        buttonColor="green"
        buttonVariant="primary"
        buttonText={t('addAdvert')}
        onButtonClick={showAdCreate ? onPlaceholderBtnClick : undefined}
        buttonFullWidth
      />
      {showAdCreate && !isPlate && (
        <CategoryDropdown
          isOpen={isDropdownOpen}
          close={() => setDropdownOpen(false)}
          dropdownCls={cls.empty_dropdown}
          hidePlate
        />
      )}
    </div>
  );

  const defaultView = (
    <div className={cls.empty}>
      <Placeholder
        imgSrc={emptyImg}
        title={t('search.empty.title')}
        text={t('search.empty.text')}
        compact={!isDesktop}
        onButtonClick={onResetClick}
        buttonColor="black"
        buttonVariant="secondary"
        buttonText={t('filters.reset')}
        buttonFullWidth
      />
    </div>
  );

  const inner = noPublished ? noPublishedView : defaultView;
  return withRoot ? <div className={cls.root}>{inner}</div> : inner;
};

export const SearchResultEmpty = memo(SearchResultEmptyComponent);
