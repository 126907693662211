export function VideoIcon(): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12527_8890)">
        <path
          d="M10.6051 38.3229H27.9689C32.3911 38.3229 34.9216 35.8382 34.9216 31.5279V16.7784C34.9216 12.4848 32.3911 10 27.9689 10H10.6051C6.30851 10 3.65234 12.4848 3.65234 16.7891V31.7089C3.65234 36.0086 6.17754 38.3229 10.6051 38.3229ZM11.2125 34.9844C8.66279 34.9844 7.21701 33.6034 7.21701 30.9988V17.3242C7.21701 14.6443 8.66279 13.3218 11.2125 13.3218H27.3615C29.8944 13.3218 31.3516 14.7029 31.3516 17.3242V30.9988C31.3516 33.6034 29.8678 34.9844 27.3455 34.9844H11.2125ZM34.2382 19.3281V23.1707L42.0134 16.8442C42.1482 16.7376 42.2434 16.6645 42.3834 16.6645C42.5578 16.6645 42.6468 16.8053 42.6468 17.0185V31.443C42.6468 31.6508 42.5578 31.7855 42.3834 31.7855C42.2434 31.7855 42.1482 31.7071 42.0134 31.612L34.2382 25.2908V29.1166L40.752 34.6567C41.6137 35.3799 42.4678 35.8715 43.3302 35.8715C44.9106 35.8715 45.9992 34.7403 45.9992 33.0335V15.4279C45.9992 13.7158 44.9106 12.5846 43.3302 12.5846C42.4625 12.5846 41.6137 13.0763 40.752 13.7994L34.2382 19.3281Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_12527_8890">
          <rect
            width="44"
            height="28.3398"
            fill="white"
            transform="translate(2 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
