import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { Nullable } from '@/app/types';

import { CloseIcon, SearchIcon, SearchOutlinedIcon } from './icons';
import cls from './InputSearch.module.scss';

type Props = {
  value?: string;
  onChange?: (v: string) => void;
  placeholder?: Nullable<string>;
  disabled?: boolean;
  autoFocus?: boolean;
  showClear?: boolean;
  fullWidth?: boolean;
  compact?: boolean;
  outlined?: boolean;
  highlightFilled?: boolean;
  returnFocusAfterDisable?: boolean;
};

export const InputSearch: React.FC<Props> = ({
  value,
  onChange,
  showClear,
  placeholder,
  disabled,
  autoFocus,
  fullWidth,
  compact,
  outlined,
  highlightFilled,
  returnFocusAfterDisable
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (v: string) => {
    if (onChange) onChange(v);
  };

  useEffect(() => {
    if (!returnFocusAfterDisable) return;

    if (!disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled, returnFocusAfterDisable]);

  return (
    <div
      className={cn(cls.root, {
        [cls.root_filled]: !!value,
        [cls.root_fullwidth]: fullWidth,
        [cls.root_compact]: compact,
        [cls.root_outlined]: outlined,
        [cls.root_highlighted]: !!value && highlightFilled
      })}
    >
      {outlined ? <SearchOutlinedIcon /> : <SearchIcon />}

      <input
        ref={inputRef}
        value={value}
        onChange={(e) => handleChange(e.currentTarget.value)}
        placeholder={placeholder || ''}
        disabled={disabled}
        autoFocus={autoFocus}
      />

      {showClear && (
        <button
          className={cn(cls.clear_btn, { [cls.clear_btn_visible]: !!value })}
          type="button"
          onClick={() => handleChange('')}
          disabled={disabled}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
};
