import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isPhoneValid,
  isPriceValid,
  isVinValid,
  SCROLLTO,
  scrollToSection
} from '@/modules/showroom/advert/create/helpers';
import { useUpdateAdvertStore } from '@/modules/showroom/advert/update/store';
import { EditorCheckList } from '@/ui/layouts/EditorLayout/EditorCheckList/EditorCheckList';
import { SLUG_NEW } from '@/utils/consts';

export function CheckList() {
  const { t } = useTranslation();

  const isSpecsFilled = useUpdateAdvertStore((s) => s.isSpecsFilled);
  const isBrokenFilled = useUpdateAdvertStore((s) => s.isBrokenFilled);
  const isMileageFilled = useUpdateAdvertStore((s) => s.isMileageFilled);
  const isPhotosFilled = useUpdateAdvertStore((s) => s.isPhotosFilled);
  const isVideoFilled = useUpdateAdvertStore((s) => s.isVideoFilled);
  const isComplectationFilled = useUpdateAdvertStore(
    (s) => s.isComplectationFilled
  );
  const isOwnerFilled = useUpdateAdvertStore((s) => s.isOwnerFilled);
  const isRegSpecsFilled = useUpdateAdvertStore((s) => s.isRegSpecsFilled);
  const isWarrantyFilled = useUpdateAdvertStore((s) => s.isWarrantyFilled);
  const isDescriptionFilled = useUpdateAdvertStore(
    (s) => s.isDescriptionFilled
  );
  const isVinFilled = useUpdateAdvertStore((s) => s.isVinFilled);
  const isAddressFilled = useUpdateAdvertStore((s) => s.isAddressFilled);
  const isPhoneFilled = useUpdateAdvertStore((s) => s.isPhoneFilled);

  const mileage = useUpdateAdvertStore((s) => s.mileage);
  const photos = useUpdateAdvertStore((s) => s.photos);
  const videoUrl = useUpdateAdvertStore((s) => s.videoUrl);

  const complectationId = useUpdateAdvertStore((s) => s.complectationId);
  const complectationOptionsItemsIds = useUpdateAdvertStore(
    (s) => s.complectationOptionsItemsIds
  );
  const isComplectationValid =
    !!complectationId || complectationOptionsItemsIds.length > 0;

  const regSpecs = useUpdateAdvertStore((s) => s.regSpecs);
  const warranty = useUpdateAdvertStore((s) => s.warranty);
  const description = useUpdateAdvertStore((s) => s.description);
  const vin = useUpdateAdvertStore((s) => s.vin);
  const addressId = useUpdateAdvertStore((s) => s.addressId);
  const contacts = useUpdateAdvertStore((s) => s.contacts);
  const phoneValid = isPhoneValid(contacts);

  const price = useUpdateAdvertStore((s) => s.price);
  const priceValid = isPriceValid(price);

  const type = useUpdateAdvertStore((s) => s.type);
  const isNewCar = type?.slug === SLUG_NEW;
  const owner = useUpdateAdvertStore((s) => s.owner);

  const resetAll = useUpdateAdvertStore((s) => s.resetAll);

  const list = useMemo(
    () => [
      {
        id: SCROLLTO.specs,
        text: t('specs'),
        checked: isSpecsFilled,
        disabled: false
      },
      {
        id: SCROLLTO.condition,
        text: t('condition'),
        checked: isBrokenFilled,
        disabled: !isSpecsFilled
      },
      {
        id: SCROLLTO.mileage,
        text: t('mileage'),
        checked: !!mileage,
        disabled: !isBrokenFilled
      },
      {
        id: SCROLLTO.photo,
        text: t('upload.image.photo'),
        checked: photos.length > 0,
        disabled: !isMileageFilled
      },
      {
        id: SCROLLTO.video,
        text: t('upload.video.title'),
        checked: !!videoUrl,
        disabled: !isPhotosFilled
      },
      {
        id: SCROLLTO.complectation,
        text: t('complectation.title'),
        checked: isComplectationValid,
        disabled: !isVideoFilled
      },
      {
        id: SCROLLTO.owner,
        text: t('owner.multiple'),
        checked: !!owner,
        hidden: isNewCar,
        disabled: !isComplectationFilled
      },
      {
        id: SCROLLTO.regspecs,
        text: t('regionSpecs'),
        checked: !!regSpecs,
        disabled: isNewCar ? !isComplectationFilled : !isOwnerFilled
      },
      {
        id: SCROLLTO.warranty,
        text: t('advertisements.warranty.title'),
        checked: !!warranty,
        disabled: !isRegSpecsFilled
      },
      {
        id: SCROLLTO.descr,
        text: t('advertisements.description.title'),
        checked: !!description,
        disabled: !isWarrantyFilled
      },
      {
        id: SCROLLTO.vin,
        text: t('vin.chassis'),
        checked: isVinValid(vin),
        disabled: !isDescriptionFilled
      },
      {
        id: SCROLLTO.showroom,
        text: t('showroom.label'),
        checked: !!addressId,
        disabled: !isVinFilled
      },
      {
        id: SCROLLTO.phone,
        text: t('showroom.contact.multiple'),
        checked: phoneValid,
        disabled: !isAddressFilled
      },
      {
        id: SCROLLTO.price,
        text: t('price'),
        checked: priceValid,
        disabled: !isPhoneFilled
      }
    ],
    [
      addressId,
      description,
      isAddressFilled,
      isBrokenFilled,
      isComplectationFilled,
      isComplectationValid,
      isDescriptionFilled,
      isMileageFilled,
      isNewCar,
      isOwnerFilled,
      isPhoneFilled,
      isPhotosFilled,
      isRegSpecsFilled,
      isSpecsFilled,
      isVideoFilled,
      isVinFilled,
      isWarrantyFilled,
      mileage,
      owner,
      phoneValid,
      photos.length,
      priceValid,
      regSpecs,
      t,
      videoUrl,
      vin,
      warranty
    ]
  );

  const filtered = list.filter((v) => {
    if (v.hidden) return false;
    return true;
  });

  return (
    <EditorCheckList
      list={filtered}
      onClick={scrollToSection}
      onResetClick={() => resetAll(true)}
    />
  );
}
