export function DeleteIcon(): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.05237 14.7586C8.34001 14.7586 8.5254 14.5763 8.51857 14.3079L8.30943 7.15178C8.30138 6.88585 8.11353 6.7092 7.83832 6.7092C7.55314 6.7092 7.36898 6.89145 7.3758 7.15861L7.57935 14.3116C7.58741 14.5832 7.77648 14.7586 8.05237 14.7586ZM10.063 14.7586C10.3457 14.7586 10.5448 14.5788 10.5448 14.3129V7.15615C10.5448 6.89022 10.3457 6.7092 10.063 6.7092C9.77905 6.7092 9.58561 6.89022 9.58561 7.15615V14.3129C9.58561 14.5788 9.77905 14.7586 10.063 14.7586ZM12.0779 14.7586C12.3495 14.7586 12.5373 14.5832 12.5454 14.3116L12.7489 7.15861C12.7557 6.89145 12.5716 6.7092 12.2864 6.7092C12.0112 6.7092 11.8233 6.88585 11.8153 7.15738L11.6118 14.3079C11.6049 14.5763 11.7903 14.7586 12.0779 14.7586ZM6.96203 4.80727H8.22179V3.38955C8.22179 3.03611 8.46902 2.80935 8.84908 2.80935H11.2645C11.6445 2.80935 11.8918 3.03611 11.8918 3.38955V4.80727H13.1515V3.3262C13.1515 2.2824 12.4853 1.66699 11.3543 1.66699H8.75926C7.62823 1.66699 6.96203 2.2824 6.96203 3.3262V4.80727ZM3.93806 5.5499H16.1922C16.5286 5.5499 16.7907 5.28274 16.7907 4.94637C16.7907 4.61245 16.5286 4.35089 16.1922 4.35089H3.93806C3.60729 4.35089 3.33398 4.61245 3.33398 4.94637C3.33398 5.28834 3.60729 5.5499 3.93806 5.5499ZM6.92817 17.0695H13.2021C14.247 17.0695 14.9257 16.4298 14.9747 15.3837L15.4439 5.41899H14.1778L13.7291 15.1804C13.7155 15.5899 13.4453 15.8619 13.0561 15.8619H7.06305C6.68503 15.8619 6.41487 15.5831 6.39561 15.1804L5.9245 5.41899H4.68085L5.15565 15.3893C5.20589 16.4354 5.87209 17.0695 6.92817 17.0695Z"
        fill="currentColor"
      />
    </svg>
  );
}
