export function PlayIcon() {
  return (
    <svg
      width="44"
      height="46"
      viewBox="0 0 44 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_11773_44431)">
        <path
          d="M13.6685 30.7188C13.6685 31.9018 14.3695 32.4809 15.2071 32.4809C15.569 32.4809 15.9495 32.3648 16.3221 32.1662L29.1119 24.6951C30.0437 24.1559 30.415 23.7343 30.415 23.0737C30.415 22.4044 30.0437 21.9917 29.1119 21.4505L16.3221 13.9813C15.9495 13.7719 15.569 13.6667 15.2071 13.6667C14.3695 13.6667 13.6685 14.2438 13.6685 15.4288V30.7188Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11773_44431"
          x="-6.5"
          y="-4.5"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11773_44431"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11773_44431"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function PauseIcon() {
  return (
    <svg
      width="40"
      height="48"
      viewBox="0 0 40 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_11773_44458)">
        <path
          d="M15.5269 34.4999C16.3024 34.4999 16.8973 33.9301 16.8973 33.1759V14.8444C16.8973 14.0903 16.3024 13.5101 15.5269 13.5101C14.7615 13.5101 14.1667 14.0903 14.1667 14.8444V33.1759C14.1667 33.9301 14.7615 34.4999 15.5269 34.4999ZM24.1927 34.4999C24.9581 34.4999 25.5631 33.9301 25.5631 33.1759V14.8444C25.5631 14.0903 24.9581 13.5101 24.1927 13.5101C23.4251 13.5101 22.8325 14.0903 22.8325 14.8444V33.1759C22.8325 33.9301 23.4251 34.4999 24.1927 34.4999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11773_44458"
          x="-7.5"
          y="-3.5"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11773_44458"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11773_44458"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function VolumeIcon() {
  return (
    <svg
      width="54"
      height="47"
      viewBox="0 0 54 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_11773_44433)">
        <path
          d="M36.0968 31.449C36.5947 31.766 37.2082 31.6443 37.5534 31.1414C38.996 29.1028 39.8333 26.3746 39.8333 23.5692C39.8333 20.7636 39.0051 18.0173 37.5534 15.9949C37.2082 15.494 36.5947 15.3723 36.0968 15.6893C35.5749 16.0182 35.5005 16.6668 35.8837 17.241C37.0639 18.9618 37.7442 21.2282 37.7442 23.5692C37.7442 25.9101 37.0457 28.1563 35.8837 29.8953C35.5095 30.4694 35.5749 31.1201 36.0968 31.449Z"
          fill="currentColor"
        />
        <path
          d="M31.6765 28.4864C32.1341 28.7891 32.7508 28.6876 33.09 28.2015C33.9833 27.0122 34.5131 25.3208 34.5131 23.5692C34.5131 21.8175 33.9833 20.1352 33.09 18.9328C32.7508 18.4507 32.1341 18.3381 31.6765 18.6519C31.1364 19.0062 31.0399 19.6581 31.4633 20.2781C32.0762 21.1489 32.422 22.3333 32.422 23.5692C32.422 24.805 32.0652 25.9783 31.4633 26.8601C31.049 27.4873 31.1364 28.123 31.6765 28.4864Z"
          fill="currentColor"
        />
        <path
          d="M26.2925 33.4938C27.1198 33.4938 27.7206 32.893 27.7206 32.0748V15.1421C27.7206 14.324 27.1198 13.6667 26.2743 13.6667C25.7095 13.6667 25.316 13.9074 24.7089 14.4774L20.0619 18.8191C19.9974 18.8816 19.9076 18.9149 19.8067 18.9149H16.6687C15.0436 18.9149 14.1667 19.82 14.1667 21.5317V25.6469C14.1667 27.3677 15.0436 28.2658 16.6687 28.2658H19.8047C19.9056 28.2658 19.9954 28.293 20.0599 28.3556L24.7089 32.7396C25.2555 33.2672 25.7197 33.4938 26.2925 33.4938Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11773_44433"
          x="-0.5"
          y="-4.5"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11773_44433"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11773_44433"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export function MutedIcon() {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_11773_44463)">
        <path
          d="M29.4466 32.5567C29.2624 33.1199 28.7489 33.4962 28.0959 33.4962C27.5305 33.4962 27.0666 33.2698 26.5112 32.7426L21.8762 28.3613C21.8027 28.2988 21.72 28.2715 21.6192 28.2715H18.476C16.861 28.2715 15.9755 27.3741 15.9755 25.6542V21.5416C15.9755 20.7394 16.1702 20.1145 16.546 19.6794L29.4466 32.5567ZM29.523 15.156V26.2898L21.9718 18.7429L26.5112 14.4916C27.118 13.913 27.5223 13.6815 28.0757 13.6815C28.9206 13.6815 29.523 14.3383 29.523 15.156Z"
          fill="currentColor"
        />
        <path
          d="M33.3884 34.5696C33.7327 34.9119 34.2934 34.9139 34.6357 34.5696C34.9669 34.2183 34.9779 33.6666 34.6357 33.3244L15.2602 13.9599C14.9159 13.6157 14.3441 13.6157 14.0019 13.9599C13.6687 14.2931 13.6687 14.874 14.0019 15.2072L33.3884 34.5696Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_11773_44463"
          x="-2.5"
          y="-4.5"
          width="55"
          height="55"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="6.75" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_11773_44463"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_11773_44463"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
