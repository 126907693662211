export const AGE_MAX_LEN = 2;
export const MIN_AGE = 16;
export const MAX_DRIVE_EXP = 50;
export const TARIFF_PRICE_MIN_LEN = 2;
export const TARIFF_PRICE_MAX_LEN = 8;
export const DEPOSIT_MIN_LEN = 2;
export const DEPOSIT_MAX_LEN = 8;
export const DEPOSIT_DAYS_MIN_LEN = 1;
export const DEPOSIT_DAYS_MAX_LEN = 3;
export const DEFAULT_VALUES = {
  age: 21,
  driveExp: 2
};
