import { axios } from '@/app/api';
import {
  ConfirmEmailReq,
  ConfirmEmailRes,
  ConfirmEmailSendCodeReq,
  CreateAccountReq,
  CreateAccountRes,
  LoginAccountReq,
  LoginAccountRes
} from '@/modules/auth/types';

const PATH = '/dealers/accounts';

export function createAccount(req: CreateAccountReq) {
  return axios.post<CreateAccountRes>(`${PATH}/create`, req);
}

export function confirmEmailSendCode(req: ConfirmEmailSendCodeReq) {
  return axios.post<unknown>(`${PATH}/confirm-email/send-code`, req);
}

export function confirmEmail(req: ConfirmEmailReq) {
  return axios.post<ConfirmEmailRes>(`${PATH}/confirm-email/do`, req);
}

export function loginAccount(req: LoginAccountReq) {
  return axios.post<LoginAccountRes>(`${PATH}/login`, req);
}

export function logoutAccount() {
  return axios.delete<unknown>(`${PATH}/logout`);
}

export function restorePassword(email: string) {
  return axios.get<unknown>(`${PATH}/password/restore`, { params: { email } });
}

export function restorePasswordConfirm(token: string, password: string) {
  return axios.post<ConfirmEmailRes>(`${PATH}/password/restore/do/${token}`, {
    password
  });
}
