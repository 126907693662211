import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PhotoType } from '@/app/types';
import { useDebounce } from '@/hooks/useDebounce';
import { useRouteUnload } from '@/hooks/useRouteUnload';
import { useCheckLoginValidity } from '@/modules/showroom/api';
import { BaseInfo } from '@/modules/showroom/ShowroomEdit/BaseInfo/BaseInfo';
import { Contact } from '@/modules/showroom/ShowroomEdit/Contact/Contact';
import { CoverLogo } from '@/modules/showroom/ShowroomEdit/CoverLogo/CoverLogo';
import { Login } from '@/modules/showroom/ShowroomEdit/Login/Login';
import { Photos } from '@/modules/showroom/ShowroomEdit/Photos/Photos';
import { Button } from '@/ui/Button/Button';
import { validateSiteUrl } from '@/utils/validate';

export type ShowroomEditValues = {
  photos: PhotoType[];
  coverUrl: string;
  description: string;
  siteUrl: string;
  name: string;
  username: string;
  logoUrl: string;
  contactName: string;
  contactPhone: string;
  contactEmail?: string;
  langIds?: number[];
};

type Props = {
  buttonText: string;
  onClick: (values: ShowroomEditValues) => void;
  initialValues?: ShowroomEditValues;
  loading?: boolean;
  setLoading?: (v: boolean) => void;
};

export function ShowroomEdit({
  initialValues,
  buttonText,
  onClick,
  loading,
  setLoading
}: Props) {
  const { t } = useTranslation();

  const [name, setName] = useState(initialValues?.name || '');
  const [siteUrl, setSiteUrl] = useState(initialValues?.siteUrl || '');
  const [description, setDescription] = useState(
    initialValues?.description || ''
  );

  // Login
  const [login, setLogin] = useState(initialValues?.username || '');
  const debouncedLogin = useDebounce(login, 800);

  const shouldCheckValidity = initialValues?.username
    ? debouncedLogin !== initialValues?.username
    : true;

  const [isLoginAvailable, isLoginValidating] = useCheckLoginValidity(
    shouldCheckValidity ? debouncedLogin : ''
  );

  // Photos
  const [coverUrl, setCoverUrl] = useState<string>(
    initialValues?.coverUrl || ''
  );
  const [logoUrl, setLogoUrl] = useState<string>(initialValues?.logoUrl || '');
  const [photos, setPhotos] = useState<PhotoType[]>(
    initialValues?.photos || []
  );

  const addPhotos = (v: PhotoType[]) => {
    setPhotos((prev) => [...prev, ...v]);
  };
  const removePhoto = (v: PhotoType['photo_url']) => {
    setPhotos((prev) => prev.filter((p) => p.photo_url !== v));
  };
  const replaceLocalPhoto = (uploaded: PhotoType, localUrl: string) => {
    setPhotos((prev) =>
      prev.map((p) => (p.photo_url === localUrl ? uploaded : p))
    );
  };

  const [contactName, setContactName] = useState(
    initialValues?.contactName || ''
  );
  const [contactPhone, setContactPhone] = useState(
    initialValues?.contactPhone || ''
  );
  const [contactEmail, setContactEmail] = useState(
    initialValues?.contactEmail || ''
  );
  const [langIds, setLangIds] = useState(initialValues?.langIds || []);

  // Submit
  const isSiteUrlValid = siteUrl ? validateSiteUrl(siteUrl) : true;
  const [formSubmitted, setFormSubmitted] = useState(false);

  const newValues = useMemo(
    () => ({
      photos,
      description,
      siteUrl,
      name,
      username: login,
      coverUrl,
      logoUrl,
      contactName,
      contactPhone,
      contactEmail
    }),
    [
      contactEmail,
      contactName,
      contactPhone,
      coverUrl,
      description,
      login,
      logoUrl,
      name,
      photos,
      siteUrl
    ]
  );

  useEffect(() => {
    if (initialValues) {
      setName(initialValues.name || '');
      setSiteUrl(initialValues.siteUrl || '');
      setDescription(initialValues.description || '');
      setLogin(initialValues.username || '');
      setCoverUrl(initialValues.coverUrl || '');
      setPhotos(initialValues.photos || []);
      setContactName(initialValues.contactName || '');
      setContactPhone(initialValues.contactPhone || '');
      setContactEmail(initialValues.contactEmail || '');
      setLangIds(initialValues.langIds || []);
      setFormSubmitted(false);
    }
  }, [initialValues]);

  useEffect(() => {
    setFormSubmitted(false);
  }, [
    contactEmail,
    contactName,
    contactPhone,
    coverUrl,
    description,
    login,
    logoUrl,
    name,
    photos,
    siteUrl
  ]);

  const handleClick = () => {
    onClick({
      ...newValues,
      langIds
    });
    setFormSubmitted(true);
  };

  useRouteUnload(
    initialValues && !formSubmitted
      ? !isEqual(initialValues, newValues)
      : false,
    t('leaveWarn')
  );

  return (
    <div>
      <CoverLogo
        name={name}
        setName={setName}
        logoUrl={logoUrl}
        setLogoUrl={setLogoUrl}
        coverUrl={coverUrl}
        setCoverUrl={setCoverUrl}
      />
      <BaseInfo
        isSiteValid={isSiteUrlValid}
        siteUrl={siteUrl}
        setSiteUrl={setSiteUrl}
        description={description}
        setDescription={setDescription}
      />
      <Login
        login={login}
        sameLogin={login === initialValues?.username}
        setLogin={setLogin}
        isLoginAvailable={isLoginAvailable}
        isLoginValidating={isLoginValidating}
        debouncedLogin={debouncedLogin}
      />
      <Photos
        photos={photos}
        setPhotos={setPhotos}
        addPhotos={addPhotos}
        removePhoto={removePhoto}
        replaceLocalPhoto={replaceLocalPhoto}
        setLoading={setLoading}
      />
      <Contact
        contactName={contactName}
        setContactName={setContactName}
        contactPhone={contactPhone}
        setContactPhone={setContactPhone}
        langIds={langIds}
        setLangIds={setLangIds}
        showLang={!initialValues}
      />
      <Button
        onClick={handleClick}
        loading={loading}
        disabled={loading || !isSiteUrlValid}
        color="green"
        fullWidth
      >
        {buttonText}
      </Button>
    </div>
  );
}
